import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Typography, Button, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useApolloClient } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

import isEmail from 'validator/lib/isEmail';

import * as Form from '@/components/form';
import { useSignUp } from '@/contexts/sign-up-context';
import { isValidPassword } from '@/utils/password-validator';
import { EmailVerifyQuery, EmailVerifyQueryVariables } from '@/graphql';
import { EMAIL_VERIFY } from '@/apollo/queries';

import PublicLayout from '../../components/layout';

interface CreateProps {
  onBack: () => void;
  onNext: () => void;
}

interface Form {
  email: string;
  password: string;
  passwordConfirmation: string;
}

const Create: React.FC<CreateProps> = ({ onBack, onNext }) => {
  const client = useApolloClient();
  const { t } = useTranslation();

  const { email, password, passwordConfirmation, update } = useSignUp();

  const { register, errors, setValue, watch } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      email: email || '',
      password: password || '',
      passwordConfirmation: passwordConfirmation || '',
    },
  });

  const validation = useMemo(
    () => ({
      email: {
        validate: async (email: string) => {
          if (!email) {
            return undefined;
          }

          if (!isEmail(email)) {
            return t('fields.email.validation.regex') as string;
          }

          const { data, errors } = await client.query<
            EmailVerifyQuery,
            EmailVerifyQueryVariables
          >({
            query: EMAIL_VERIFY,
            variables: { email },
          });

          if (errors) {
            return errors.join('; ');
          }

          if (data && data.emailVerify) {
            return t('fields.email.validation.inuse') as string;
          }
          return undefined;
        },
      },
      password: {
        validate: (value: string) =>
          value && !isValidPassword(value) ? '' : undefined,
      },
      passwordConfirmation: {
        validate: (value: string) =>
          value && watch('password') !== value
            ? (t('fields.confirm-password.validation.regex') as string)
            : undefined,
      },
    }),
    [client, t, watch]
  );

  const changeField = useCallback(
    (prop: keyof Form) => (e: React.ChangeEvent<{ value: unknown }>) => {
      const value = String(e.target.value).replace(/\s/g, '');
      setValue(prop, value, {
        shouldDirty: true,
        shouldValidate: true,
      });

      switch (prop) {
        case 'email':
          return update({ email: value });
        case 'password': {
          // Revalidate passwordConfirmation
          setValue('passwordConfirmation', watch('passwordConfirmation'), {
            shouldDirty: true,
            shouldValidate: true,
          });

          return update({ password: value });
        }
        case 'passwordConfirmation':
          return update({ passwordConfirmation: value });
      }
    },
    [setValue, update, watch]
  );

  useEffect(() => {
    register({ name: 'email' }, validation.email);
    register({ name: 'password' }, validation.password);
    register({ name: 'passwordConfirmation' }, validation.passwordConfirmation);
  }, [
    register,
    validation.passwordConfirmation,
    validation.email,
    validation.password,
  ]);

  const isValid = useMemo(() => {
    const values = watch();

    return (
      isEmpty(errors) &&
      !!values.email &&
      !!values.password &&
      !!values.passwordConfirmation
    );
  }, [watch, errors]);

  return (
    <PublicLayout title={t('sign-up.create.title')}>
      <Box paddingY={7}>
        <Form.Row noPadding>
          <Form.TextField
            fullWidth
            required
            data-cy-email
            variant="outlined"
            name="email"
            label={t('fields.email.label')}
            errors={errors.email}
            value={watch('email')}
            onChange={changeField('email')}
          />
        </Form.Row>
        <Box marginTop={2} marginBottom={6}>
          <Typography color="textPrimary" variant="caption">
            {t('fields.email.help')}
          </Typography>
        </Box>
        <Form.Row noPadding>
          <Form.PasswordField
            fullWidth
            required
            data-cy-password
            variant="outlined"
            name="password"
            label={t('fields.password.label')}
            errors={errors.password}
            value={watch('password')}
            onChange={changeField('password')}
          />
        </Form.Row>
        <Box marginTop={2} marginBottom={6}>
          <Typography color="textPrimary" variant="caption">
            {t('fields.password.help')}
          </Typography>
        </Box>
        <Form.Row noPadding>
          <Form.PasswordField
            fullWidth
            required
            data-cy-confirmation-password
            variant="outlined"
            name="passwordConfirmation"
            label={t('fields.confirm-password.label')}
            errors={errors.passwordConfirmation}
            value={watch('passwordConfirmation')}
            onChange={changeField('passwordConfirmation')}
          />
        </Form.Row>
      </Box>
      <Button
        fullWidth
        data-cy-next
        color="primary"
        variant="contained"
        disabled={!isValid}
        onClick={onNext}
      >
        <span style={{ color: '#fff' }}>{t('common.next')}</span>
      </Button>
      <Box paddingTop={7}>
        <Typography variant="caption">
          {t('common.do-you-have-account')}
          <Link className="m-l" component={RouterLink} to="/sign-in">
            {t('common.do-you-have-account-link')}
          </Link>
        </Typography>
      </Box>
    </PublicLayout>
  );
};

export default Create;
