import React, { useState, useCallback, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import Create from './create';
import PersonalInfo from './personal-info';
import ChoseProfessions from './choose-profession';
import ProfessionInfo from './profession-info';
import Completed from './completed';
import { SS_KEY_STEP_SIGNUP } from '@/utils/constants';

const SignUp: React.FC = () => {
  const jsonStepData = sessionStorage.getItem(SS_KEY_STEP_SIGNUP);
  const initialStepData = jsonStepData ? JSON.parse(jsonStepData) : 1;
  const [currentStep, setStep] = useState<number>(initialStepData);
  const [stepStartTime, setStepStartTime] = useState<number>(Date.now());

  useEffect(() => {
    datadogRum.startSessionReplayRecording();
  });

  // Track when the step starts
  const handleStepStart = useCallback((step: number) => {
    setStepStartTime(Date.now());
    datadogRum.addAction(`sign_up_step${step}_start`, {
      step,
      timestamp: Date.now(),
    });
  }, []);

  // Track when the step ends
  const handleStepEnd = useCallback(
    (step: number) => {
      const timeSpent = Date.now() - stepStartTime;
      datadogRum.addAction('sign_up_step_time_spent', {
        step,
        timeSpent,
        timestamp: Date.now(),
      });
    },
    [stepStartTime]
  );

  // Go to the next step
  const next = useCallback(() => {
    handleStepEnd(currentStep);

    setStep((prev) => {
      const nextStep = prev + 1;
      handleStepStart(nextStep);
      return nextStep;
    });
  }, [currentStep, handleStepEnd, handleStepStart]);

  // Go to the previous step
  const back = useCallback(() => {
    handleStepEnd(currentStep);

    setStep((prev) => {
      const previousStep = prev - 1;
      handleStepStart(previousStep);
      datadogRum.addAction('sign_up_step_back', {
        fromStep: currentStep,
        toStep: previousStep,
      });
      return previousStep;
    });
  }, [currentStep, handleStepEnd, handleStepStart]);

  useEffect(() => {
    if (!jsonStepData) {
      handleStepStart(1);
    }
    sessionStorage.setItem(SS_KEY_STEP_SIGNUP, JSON.stringify(currentStep));
  }, [currentStep, handleStepStart, jsonStepData]);

  switch (currentStep) {
    case 1:
      return <Create onNext={next} onBack={back} />;
    case 2:
      return <PersonalInfo onNext={next} onBack={back} />;
    case 3:
      return <ChoseProfessions onNext={next} onBack={back} />;
    case 4:
      return <ProfessionInfo onNext={next} onBack={back} />;
    case 5:
      return <Completed />;
    default:
      return <></>;
  }
};

export default SignUp;
