import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, IconButton, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { datadogRum } from '@datadog/browser-rum';
import { isBoolean } from 'lodash';

import AppButton from '@/components/app-button';
import CheckCircleIcon from '@/components/icons/check-circle';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { useUser } from '@/contexts/user-context';
import {
  SubscriptionPeriodEnum,
  SubscriptionStatus,
  SubscriptionTypes,
} from '@/type';
import { useUserDiscountsQuery } from '@/graphql';
import { plansConfig } from '../plansConfig';
import SubsAgreementText from '../subs-agreement-text';
import PlanOption from './components/plan-option';
import { Plan } from '../types';
import { useStyles } from './styles';
import { Skeleton } from '@material-ui/lab';

const ChoosePlan = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { user } = useUser();
  const { subsDiscountsEnabled } = useFeatureFlags();
  const { isUserPremiumByPayment, isUserStudent, isSpecificProfession } =
    useGetPremiumData();

  const { data } = useUserDiscountsQuery({
    skip: !subsDiscountsEnabled,
  });
  const activeDiscounts = data?.discounts?.filter((it) =>
    it?.subscriptionType?.includes(isUserStudent ? 'STUDENT' : 'PROFESSIONAL')
  );

  const closePage = () => {
    history.replace('/home');
  };

  const filterPlans = useCallback(
    (plan: any) => {
      if (!isSpecificProfession) {
        if (
          (user?.customer?.subscription?.subscriptionType?.includes('Yearly') &&
            user?.customer?.subscription?.status ===
              SubscriptionStatus.ACTIVE) ||
          user?.customer?.subscription?.status === SubscriptionStatus.TRIALING
        ) {
          return false;
        }
        let premiumPlans = [
          SubscriptionTypes.StudentMonthly,
          SubscriptionTypes.StudentYearly,
          SubscriptionTypes.ProfessionalMonthly,
          SubscriptionTypes.ProfessionalYearly,
        ].filter((it) =>
          it.includes(isUserStudent ? 'Student' : 'Professional')
        );
        if (isUserPremiumByPayment) {
          premiumPlans = premiumPlans.filter((it) => it.includes('Yearly'));
        }
        return premiumPlans.includes(plan.id);
      }
      return false;
    },
    [isSpecificProfession, isUserPremiumByPayment, isUserStudent, user]
  );

  const filteredPlansConfig = plansConfig.filter(filterPlans);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>();

  useEffect(() => {
    !selectedPlan &&
      filteredPlansConfig.length &&
      isBoolean(isSpecificProfession) &&
      isBoolean(isUserPremiumByPayment) &&
      isBoolean(isUserStudent) &&
      setSelectedPlan(filteredPlansConfig[0]);
  }, [
    filteredPlansConfig,
    isSpecificProfession,
    isUserPremiumByPayment,
    isUserStudent,
    selectedPlan,
  ]);

  const choosePlan = () => {
    datadogRum.addAction('subscription', {
      event: `go to payments, plan "${selectedPlan?.id}" `,
    });
    const historyState: any = history.location.state;
    if (historyState) historyState['isRedirectedFormPlanChooser'] = true;
    history.push(`/payment?planId=${selectedPlan?.id}`);
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <Box>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={closePage}
        >
          <CloseIcon htmlColor="#4A647C" />
        </IconButton>
        <Typography variant="h2">{t('in-app-subscriptions.title')}</Typography>
        <Box mt={6} mb={5}>
          {[1, 2, 3, 4].map((it) => (
            <Box key={it} mt={2} className={classes.adventageListItem}>
              <CheckCircleIcon className={classes.circleIcon} />
              <Typography variant="subtitle2">
                {t(`in-app-subscriptions.adventages.${it}`)}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box className={classes.subscriptionOptions}>
          {!user ? (
            <Box display="flex" justifyContent="space-between" flexGrow={1}>
              {[...Array(2)].map((i, idx) => (
                <Skeleton
                  variant="rect"
                  key={idx}
                  height={118}
                  style={{ width: '48%' }}
                />
              ))}
            </Box>
          ) : (
            filteredPlansConfig?.map((plan, index) => (
              <PlanOption
                key={plan.id}
                plan={plan}
                isSelected={selectedPlan?.id === plan.id}
                activeDiscounts={activeDiscounts}
                onSelect={() => setSelectedPlan(plan)}
              />
            ))
          )}

          {isSpecificProfession && (
            <Typography className={classes.subscriptionPlaceholderText}>
              {t('in-app-subscriptions.no-subscription-caption')}
            </Typography>
          )}
        </Box>
        <AppButton
          fullWidth
          data-cy-forgot-btn
          color="primary"
          variant="contained"
          disabled={!selectedPlan}
          onClick={choosePlan}
        >
          {t(
            selectedPlan?.period === SubscriptionPeriodEnum.P1Y
              ? 'subscription.start-annual-plan'
              : 'subscription.start-monthly-plan'
          )}
        </AppButton>
        <SubsAgreementText
          subscriptionPeriod={selectedPlan?.period}
          price={selectedPlan?.price}
          discountPrice={selectedPlan?.discountPrice}
        />
      </Box>
    </Paper>
  );
};

export default ChoosePlan;
