import React, { useState, useMemo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useApolloClient } from '@apollo/client';
import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';

import { Box, makeStyles, Paper, Typography } from '@material-ui/core';

import isPostalCode from 'validator/lib/isPostalCode';
import { checkNameIsValid } from '@/utils/helpers';

import StackLayout from '@/screens/main/components/stack-layout';
import * as Form from '@/components/form';
import AppButton from '@/components/app-button';
import Loading from '@/components/loading';
import { useUser } from '@/contexts/user-context';
import { USERNAME_VERIFY } from '@/apollo/queries';
import {
  useUserMeQuery,
  UsernameVerifyQuery,
  UsernameVerifyQueryVariables,
  useUpdateUserProfileMutation,
} from '@/graphql';
import { UserGender } from '@/type';

import EditBtn from '../components/edit-btn';
import { useAuth } from '@/contexts/auth-context';
import { MIN_REG_AGE } from '@/utils/constants';

interface Form {
  firstName: string;
  lastName: string;
  username: string;
  gender: string;
  dateOfBirth: DateTime | null;
  postcode: string;
}

const MyProfilePersonal: React.FC = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const { t } = useTranslation();
  const { user } = useUser();
  const me = useUserMeQuery();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const toggleMode = useCallback(
    (value: boolean) => () => {
      setIsEditMode(value);
    },
    []
  );

  const [updateUserProfile, { loading: saveLoadig }] =
    useUpdateUserProfileMutation();

  const { errors, watch, control } = useForm<Form>({
    mode: 'onChange',
  });
  const { signIn } = useAuth();
  const save = useCallback(async () => {
    const res = await updateUserProfile({
      variables: {
        firstName: watch('firstName'),
        lastName: watch('lastName'),
        username: watch('username'),
        gender: Number(watch('gender')),
        dateOfBirth: watch('dateOfBirth')?.toISODate() || '',
        postcode: watch('postcode'),
      },
    });
    const newToken = res?.data?.updateUserData?.token;
    if (newToken) {
      signIn(newToken);
      me.refetch();
    }
    setIsEditMode(false);
  }, [updateUserProfile, watch, signIn, me]);

  const validation = useMemo(
    () => ({
      firstName: {
        validate: (value: string) =>
          value && !checkNameIsValid(value)
            ? (t('fields.first-name.validation.regex') as string)
            : undefined,
      },
      lastName: {
        validate: (value: string) =>
          value && !checkNameIsValid(value)
            ? (t('fields.last-name.validation.regex') as string)
            : undefined,
      },
      username: {
        validate: async (username: string) => {
          if (!username) {
            return undefined;
          }

          if (username === user?.username) {
            return undefined;
          }

          const { data: validationData, errors } = await client.query<
            UsernameVerifyQuery,
            UsernameVerifyQueryVariables
          >({
            query: USERNAME_VERIFY,
            variables: { username },
          });

          if (errors) {
            return errors.join('; ');
          }

          if (validationData && validationData.usernameVerify) {
            return t('fields.user-name.validation.already-taken') as string;
          }

          return undefined;
        },
      },
      postcode: {
        validate: (value: string) =>
          value && (value === '00000' || !isPostalCode(value, 'DE'))
            ? (t('fields.post-code.validation.regex') as string)
            : undefined,
      },
    }),
    [client, t, user]
  );

  const genders = React.useMemo(
    () => [
      {
        value: String(UserGender.Male),
        label: t(`common.gender.${UserGender.Male}`) as string,
      },
      {
        value: String(UserGender.Female),
        label: t(`common.gender.${UserGender.Female}`) as string,
      },
      {
        value: String(UserGender.Divers),
        label: t(`common.gender.${UserGender.Divers}`) as string,
      },
    ],
    [t]
  );

  const isValid = useMemo(() => {
    const { firstName, lastName, username, gender, dateOfBirth, postcode } =
      watch();

    return (
      isEmpty(errors) &&
      !!firstName &&
      !!lastName &&
      !!username &&
      !!gender &&
      !!dateOfBirth &&
      !!postcode
    );
  }, [errors, watch]);

  const isDirty = useMemo(() => {
    const { firstName, lastName, username, gender, dateOfBirth, postcode } =
      watch();

    return (
      firstName !== user?.firstName ||
      lastName !== user?.lastName ||
      username !== user?.username ||
      gender !== String(user?.gender) ||
      dateOfBirth !== user?.dateOfBirth ||
      postcode !== user?.postcode
    );
  }, [user, watch]);

  if (!user)
    return (
      <StackLayout title={t('my-profile.personal.title')} back="/my-profile">
        <Loading />
      </StackLayout>
    );

  return (
    <StackLayout title={t('my-profile.personal.title')} back="/my-profile">
      <Box clone height="100%" boxSizing="border-box" paddingY={7} paddingX={4}>
        <Paper elevation={0}>
          {isEditMode ? (
            <>
              <Form.Row columns={2}>
                <Controller
                  render={(props) => (
                    <Form.TextField
                      fullWidth
                      required
                      autoFocus
                      data-cy-first-name
                      variant="outlined"
                      label={t('fields.first-name.label')}
                      errors={errors.firstName}
                      {...props}
                      value={props.value.slice(0, 50)}
                    />
                  )}
                  control={control}
                  name="firstName"
                  rules={validation.firstName}
                  defaultValue={user?.firstName}
                />
                <Controller
                  render={(props) => (
                    <Form.TextField
                      fullWidth
                      required
                      data-cy-last-name
                      variant="outlined"
                      label={t('fields.last-name.label')}
                      errors={errors.lastName}
                      {...props}
                      value={props.value.slice(0, 50)}
                    />
                  )}
                  control={control}
                  name="lastName"
                  rules={validation.lastName}
                  defaultValue={user?.lastName}
                />
              </Form.Row>
              <Form.Row>
                <Controller
                  render={(props) => (
                    <Form.TextField
                      fullWidth
                      required
                      data-cy-user-name
                      variant="outlined"
                      label={t('fields.user-name.label')}
                      errors={errors.username}
                      {...props}
                    />
                  )}
                  control={control}
                  name="username"
                  rules={validation.username}
                  defaultValue={user?.username}
                />
              </Form.Row>
              <Form.Row>
                <Controller
                  render={(props) => (
                    <Form.RadioField
                      fullWidth
                      data-cy-gender
                      label={t('fields.gender.label')}
                      options={genders}
                      {...props}
                    />
                  )}
                  control={control}
                  name="gender"
                  defaultValue={String(user?.gender)}
                />
              </Form.Row>
              <Form.Row columns={2}>
                <Controller
                  render={(props) => (
                    <Form.DateField
                      autoOk
                      fullWidth
                      disableFuture
                      disableToolbar
                      data-cy-date-of-birth
                      openTo="year"
                      variant="inline"
                      inputVariant="outlined"
                      placeholder="TT/MM/JJJJ"
                      views={['year', 'month', 'date']}
                      label={t('fields.date-of-birth.label')}
                      maxDate={DateTime.local().minus({ years: MIN_REG_AGE })}
                      {...props}
                    />
                  )}
                  control={control}
                  name="dateOfBirth"
                  defaultValue={DateTime.fromISO(user?.dateOfBirth)}
                />
                <Controller
                  render={(props) => (
                    <Form.TextField
                      fullWidth
                      required
                      data-cy-post-code
                      variant="outlined"
                      label={t('fields.post-code.label')}
                      errors={errors.postcode}
                      {...props}
                      value={props.value?.replace(/[^0-9]/gi, '')}
                    />
                  )}
                  control={control}
                  name="postcode"
                  rules={validation.postcode}
                  defaultValue={user?.postcode}
                />
              </Form.Row>
              <Box px={6} py={4} mb={2} mt={6} className={classes.box}>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  className={classes.caption}
                >
                  {t('fields.change-email.caption')}
                </Typography>
                <br />
                <Typography variant="caption" color="textPrimary">
                  <Trans
                    i18nKey="fields.change-email.text"
                    components={{
                      link1: (
                        /* eslint-disable-next-line jsx-a11y/anchor-has-content */
                        <a
                          href="mailto:info@humeo.de"
                          className={classes.link}
                        />
                      ),
                    }}
                    values={{ email: 'info@humeo.de' }}
                  />
                </Typography>
              </Box>
              <AppButton
                fullWidth
                data-cy-save-changes
                color="primary"
                variant="contained"
                disabled={!isValid || !isDirty}
                loading={saveLoadig}
                onClick={save}
              >
                {t('common.save-changes')}
              </AppButton>
            </>
          ) : (
            <>
              <Form.InfoField label={t('fields.first-name.label')}>
                {user?.firstName}
              </Form.InfoField>
              <Form.InfoField label={t('fields.last-name.label')}>
                {user?.lastName}
              </Form.InfoField>
              <Form.InfoField label={t('fields.user-name.label')}>
                {user?.username}
              </Form.InfoField>
              <Form.InfoField label={t('fields.gender.label')}>
                {t(`common.gender.${user?.gender}`)}
              </Form.InfoField>
              {user?.dateOfBirth && (
                <Form.InfoField label={t('fields.date-of-birth.label')}>
                  {DateTime.fromISO(user?.dateOfBirth).toFormat('dd/MM/yyyy')}
                </Form.InfoField>
              )}
              <Form.InfoField label={t('fields.post-code.label')}>
                {user?.postcode}
              </Form.InfoField>
              <Form.InfoField label={t('fields.email.label')}>
                {user?.email}
              </Form.InfoField>
              <EditBtn onClick={toggleMode(true)} />
            </>
          )}
        </Paper>
      </Box>
    </StackLayout>
  );
};

export default MyProfilePersonal;

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  box: {
    backgroundColor: 'rgba(226, 245, 253, 0.48)',
    border: '1px solid #E6EEFA',
    borderRadius: 4,
  },
  caption: {
    fontWeight: 'bold',
  },
}));
