import { SubscriptionTypes, SubscriptionPeriodEnum } from '@/type';
import { Plan } from './types';

export const plansConfig: Plan[] = [
  {
    id: SubscriptionTypes.StudentYearly,
    period: SubscriptionPeriodEnum.P1Y,
    title: 'subscription.annual',
    info: 'subscription.annual-info',
    price: '89.00€',
    profit: '7,41€',
    discountPrice: '44.50€',
    discountProfit: '3,71€',
    discountPercent: '50',
    paymentProfitTitle: 'subscription.yearly-payment-profit-title',
    paymentProfitValue: '-44,50€',
    save: 16,
    btnText: 'subscription.start-annual-plan',
  },
  {
    id: SubscriptionTypes.StudentMonthly,
    period: SubscriptionPeriodEnum.P1M,
    title: 'subscription.monthly',
    info: 'subscription.monthly-info',
    price: '8,90€',
    discountPrice: '5,99€',
    discountPercent: '33',
    btnText: 'subscription.start-monthly-plan',
    paymentProfitTitle: 'subscription.monthly-payment-profit-title',
    paymentProfitValue: '-2,91€',
  },
  {
    id: SubscriptionTypes.ProfessionalYearly,
    period: SubscriptionPeriodEnum.P1Y,
    title: 'subscription.annual',
    info: 'subscription.annual-info',
    price: '129,00€',
    profit: '10,75€',
    discountPrice: '64,50€',
    discountProfit: '5,38€',
    discountPercent: '50',
    paymentProfitTitle: 'subscription.yearly-payment-profit-title',
    paymentProfitValue: '-64,50€',
    save: 16,
    btnText: 'subscription.start-annual-plan',
  },
  {
    id: SubscriptionTypes.ProfessionalMonthly,
    period: SubscriptionPeriodEnum.P1M,
    title: 'subscription.monthly',
    info: 'subscription.monthly-info',
    price: '12,90€',
    discountPrice: '8,60€',
    discountPercent: '33',
    btnText: 'subscription.start-monthly-plan',
    paymentProfitTitle: 'subscription.monthly-payment-profit-title',
    paymentProfitValue: '-4,30€',
  },
  {
    id: SubscriptionTypes.Code,
    period: SubscriptionPeriodEnum.CODE,
    title: 'subscription.activation-code',
    info: 'subscription.code-info',
    subtitle: 'subscription.activation-code-descr',
    btnText: 'subscription.start-with-code',
  },
  {
    id: SubscriptionTypes.Referral,
    period: SubscriptionPeriodEnum.REFERRAL,
    title: 'subscription.referral',
    subtitle: 'subscription.referral-descr',
    btnText: 'subscription.start-with-referral',
    info: '',
  },
];
