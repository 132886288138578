import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import { useAuth } from '@/contexts/auth-context';
import { useUser } from '@/contexts/user-context';

import { useUploadPhotoMutation } from '@/graphql';
import StackLayout from '@/screens/main/components/stack-layout';
import { useStyles } from './styles';
import { MAX_AVATAR_SIZE_MB } from '@/utils/constants';
import { ProfessionTypesEnum } from '@/type';

const MyProfile: React.FC = () => {
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const { user, contentAvailable } = useUser();
  const classes = useStyles();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [avatarPath, setAvatarPath] = useState<string>('');

  const [uploadPhoto, { loading: updateAvaLoading }] = useUploadPhotoMutation();

  const uploadAvatar = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!!event.target?.files?.length) {
        setUploadError(null);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const file = event.target.files[0] as any;
        const fileSizeMb = file?.size / 1000000;
        if (fileSizeMb > MAX_AVATAR_SIZE_MB) {
          setUploadError(t(`my-profile.avatar.error-max-size`));
        } else {
          const res = await uploadPhoto({
            variables: { file },
          });
          const path = res.data?.uploadPhoto?.user?.avatar;
          if (path) setAvatarPath(path);
        }
      }
    },
    [uploadPhoto, t]
  );

  const logout = useCallback(() => {
    datadogRum.clearUser();
    signOut();
  }, [signOut]);

  const description = useMemo(() => {
    if (!user?.profession?.type?.type) return <Skeleton />;

    switch (user.profession.type.type) {
      case ProfessionTypesEnum.Doctor:
      case ProfessionTypesEnum.MedicalAssistant:
      case ProfessionTypesEnum.Other:
        return user?.speciality?.title || '';
      case ProfessionTypesEnum.Student:
        return user?.semester?.title || '';
      default:
        return '';
    }
  }, [user]);

  const fullNameNode = !!user ? (
    `${user?.firstName} ${user?.lastName}`
  ) : (
    <Skeleton />
  );

  const type = user?.profession?.type?.type;

  const professionLabelNode = !!type ? (
    t(`my-profile.profession-information-${type}.menu-label`)
  ) : (
    <Skeleton width="80%" />
  );

  return (
    <StackLayout title={t('my-profile.title')} back="/profile">
      <Box clone height="100%" boxSizing="border-box">
        <Paper elevation={0}>
          <Box paddingY={5} paddingX={4}>
            <Box marginX={-3}>
              <input
                accept="image/*"
                id="icon-button-file"
                type="file"
                style={{ display: 'none' }}
                onChange={uploadAvatar}
              />
              <IconButton aria-label="upload">
                <label
                  htmlFor="icon-button-file"
                  style={{ position: 'relative' }}
                >
                  <Avatar
                    aria-label="avatar"
                    src={(avatarPath || user?.avatar) ?? undefined}
                    className={classes.avatar}
                  />
                  {updateAvaLoading && (
                    <Box className={classes.loadingBox}>
                      <CircularProgress />
                    </Box>
                  )}
                </label>
              </IconButton>
            </Box>
            <Typography variant="caption" color="error">
              {uploadError}
            </Typography>
            <Box paddingTop={5}>
              <Typography
                variant="h2"
                gutterBottom
                className="text-overflow-ellipsis"
              >
                {fullNameNode}
              </Typography>
              <Typography variant="subtitle2">{description}</Typography>
            </Box>
          </Box>
          <Divider />
          <List>
            <ListItem
              button
              data-cy-my-profile-personal
              to="/my-profile/personal"
              className={classes.item}
              component={RouterLink}
            >
              <ListItemText
                primary={t('my-profile.personal-information.menu-label')}
              />
              <ListItemSecondaryAction className={classes.action}>
                <ChevronRight color="disabled" />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              to={`/my-profile/profession/${type}`}
              button
              className={classes.item}
              component={RouterLink}
            >
              <ListItemText primary={professionLabelNode} />
              <ListItemSecondaryAction className={classes.action}>
                <ChevronRight color="disabled" />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              button
              to="/my-profile/network"
              disabled={!contentAvailable || !user?.network}
              className={classes.item}
              component={RouterLink}
            >
              <ListItemText primary={t('my-profile.network.menu-label')} />
              <ListItemSecondaryAction className={classes.action}>
                <ChevronRight color="disabled" />
              </ListItemSecondaryAction>
            </ListItem>
            {user?.customer?.defaultCard?.last4 && (
              <ListItem
                button
                to="/my-profile/payment-method"
                disabled={!contentAvailable || !user?.network}
                className={classes.item}
                component={RouterLink}
              >
                <ListItemText primary={t('my-profile.payment-method.title')} />
                <ListItemSecondaryAction className={classes.action}>
                  <ChevronRight color="disabled" />
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
          <Box color="error.main" marginTop={12} paddingX={2}>
            <Button color="inherit" onClick={logout}>
              {t('my-profile.log-out')}
            </Button>
          </Box>
        </Paper>
      </Box>
    </StackLayout>
  );
};

export default MyProfile;
